<template>
  <b-modal
    :visible="exibir"
    id="imagem-modal"
    size="lg"
    centered
    hide-footer
    @change="$emit('update:exibir', $event)"
  >
    <b-carousel id="carousel-1" ref="myCarousel" :interval="60000">
      <b-carousel-slide v-for="(img, index) in imagesList" :key="index">
        <template #img>
          <div class="d-flex justify-content-center">
            <image-zoom
              clickMessage="<span class='vh--icon'>⚲</span> Clique para ampliar"
              :zoom-amount="3"
              :click-zoom="true"
              img-class="img-fluid"
              :regular="img.url"
              :zoom="img.url"
            >
            </image-zoom>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <div
      class="d-flex align-items-center justify-content-center mt-1"
      v-if="images.length > 1"
    >
      <b-button-group>
        <b-button ref="btnPrev" variant="outline-primary" @click="prev">
          Anterior
        </b-button>

        <b-button variant="outline-primary" ref="btnNext" @click="next">
          Próxima
        </b-button>
      </b-button-group>
    </div>
  </b-modal>
</template>
<script>
import {
  BButton,
  BButtonGroup,
  BCarousel,
  BCarouselSlide,
  BSkeletonImg,
} from 'bootstrap-vue'
import imageZoom from 'vue-image-zoomer'
export default {
  name: 'modal-carousel',
  components: {
    BButton,
    BButtonGroup,
    BCarousel,
    BCarouselSlide,
    BSkeletonImg,
    imageZoom,
  },
  props: {
    exibir: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
    imageClick: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    imagesList() {
      const images = [...this.images]

      const getIndexImage = images.findIndex(
        (img) => img.id === this.imageClick.id,
      )

      if (getIndexImage > 0 && images.length > 1) {
        images.splice(getIndexImage, 1)
        images.unshift(this.imageClick)
      }

      return images
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    prev() {
      this.$refs.myCarousel.prev()
    },
    next() {
      this.$refs.myCarousel.next()
    },
    handleKeydown(event) {
      if (event.key === 'ArrowLeft') {
        this.prev()
      } else if (event.key === 'ArrowRight') {
        this.next()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .img-fluid {
  height: 70vh !important;
}
</style>
