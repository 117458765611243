<template>
  <div class="image-overlay">
    <b-img :src="url" v-bind="$attrs" />
    <div class="overlay">
      <span class="overlay-text">{{ overlayText }}</span>
    </div>
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'
export default {
  name: 'image-overlay',
  components: {
    BImg,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    overlayText: {
      type: String,
      default: 'Clique Aqui',
    },
  },
}
</script>
<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
}

.overlay-text {
  font-size: 1.2rem;
  font-weight: bold;
}

.image-overlay:hover .overlay {
  cursor: pointer;
  opacity: 1;
}
</style>
